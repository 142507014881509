import React from "react";
import styles from "./PricingInfo.module.scss";
import { CheckCircle } from "react-feather";

//TODO UPDATE
export default function PricingInfo(props) {
    return (
        <>
            <div className={styles.formContainer}>
                {props.advisor.pricingV2.map((pricingModel, index) => (
                    <div key={index} className={styles.pricingModelContainer}>
                        <div className={styles.checkCircle}>
                            <CheckCircle size={20} color={"var(--adfi_white)"}></CheckCircle>
                        </div>
                        <div className={styles.pricingTextContainer}>
                            <div className={styles.pricingTextValue}>{getPricingDisplayName(pricingModel).name}</div>
                            <div className={`${styles.pricingTextDescription} ${styles.bold}`}>
                                {getPricingDisplayName(pricingModel).details}
                            </div>
                            <div className={styles.pricingTextDescription}>
                                {getPricingDisplayName(pricingModel).description}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export function getPricingDisplayName(model) {
    function capitalizeFirstLetter(word) {
        if (!word) return ""; // Handle empty or undefined input
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }

    //todo format $ percent
    switch (model.typeName) {
        case "AUM":
            return {
                name: "AUM",
                details:
                    model.minRange && model.maxRange
                        ? `Minimum Range: ${model.minRange}%, Maximum Range: ${model.maxRange}%`
                        : "",
                description:
                    "Advisors who charge an AUM fee, assets under management fee, charge a percentage fee based on the amount of assets a client has. This fee typically begins at 1% of AUM, but is negotiable between the advisor and client. It is not uncommon for advisors to charge much less than 1% based on the amount of assets a client has.",
            };
        case "FLAT_FEE":
            return {
                name: "Flat Fee",
                details:
                    model.minRange && model.maxRange && model.frequency
                        ? `Minimum Range: $${model.minRange}, Maximum Range: $${model.maxRange}, Frequency: ${capitalizeFirstLetter(model.frequency)}`
                        : "",
                description:
                    "Advisors who charge flat fees for their services charge specific dollar amounts, not percentage points. This can be referred to as a retainer. Flat fees are often used for ongoing services or comprehensive planning.",
            };
        case "HOURLY":
            return {
                name: "Hourly Fee",
                details: model.rate ? `Rate: $${model.rate}` : "",
                description:
                    "Advisors who charge hourly fees bill clients based on the time spent working on their financial matters. This fee structure is often used for specific tasks or consultations. Hourly rates can vary widely depending on the advisor's experience and expertise. This approach allows clients to pay only for the time and services they need, making it flexible for those who don't require ongoing management.",
            };
        case "ONE_TIME":
            return {
                name: "One Time Fee",
                details: model.cost ? `Cost: ${model.cost}` : "",
                description:
                    "Advisors who charge a one-time financial plan fee provide a comprehensive financial analysis and strategy for a set price. This fee covers the creation of a detailed financial plan tailored to the client's specific goals and circumstances.",
            };
        case "FEES_INFORMATION":
            return {
                name: "Fees and Commissions Information",
                details: (
                    <>
                        <a href={model.link} target="_blank" rel="noopener noreferrer">
                            Click here
                        </a>{" "}
                        for more information related to fees and commissions.
                    </>
                ),
            };
    }
}
