import React, { useState, useEffect } from "react";
import styles from "./IntroForm.module.scss";
import { Info, Check } from "react-feather";
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import consumerProfileService from "../../../services/consumerProfile.service";
import ErrorBanner from "../../ErrorBanner/ErrorBanner";
import validateExternalLinks from "../../../Utils/validateExternalLinks";

export default function IntroForm(props) {
    const [characterCount, setCharacterCount] = useState(0);
    const [infoText, setInfoText] = useState(props.user.bio ? props.user.bio.toString() : "");
    const [videoText, setVideoText] = useState(props.user.bioVideoLink ?? "");
    const [websiteText, setWebsiteText] = useState(props.user.advisorWebsiteURL ?? "");
    const [linkedInText, setLinkedInText] = useState(props.user.linkedInURL ?? "");
    const [twitterText, setTwitterText] = useState(props.user.twitterURL ?? "");
    const [submitDisabled, setSubmitDisabled] = useState(
        props.user.bio ? props.user.bio?.toString().length <= 0 : true,
    );
    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);

    const firmAdminAuth = props.firmAdminAuth;

    useEffect(() => {
        handleChange(infoText);
    }, [infoText]);

    function handleChange(value) {
        setCharacterCount(value.length);
        if (value.length <= 2500) {
            setInfoText(value);
        }
        if (value.length > 0) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    }

    function cancelButton() {
        props.user.bio ? setInfoText(props.user.bio.toString()) : setInfoText("");
        props.onClose();
    }

    function validateFields(e) {
        e.preventDefault();
        setErrors({});
        const validate = validateExternalLinks({ twitterText, linkedInText, websiteText });

        if (Object.keys(validate).length !== 0) {
            setErrors(validate);
            return;
        } else {
            onSubmit();
        }
    }

    async function onSubmit() {
        setSubmitDisabled(true);
        setShowErrorBanner(false);

        const advisorColumnData = [
            {
                bio: infoText,
            },
            {
                bioVideoLink: videoText,
            },
            {
                advisorWebsiteURL: websiteText,
            },
            {
                twitterURL: twitterText,
            },
            {
                linkedInURL: linkedInText,
            },
        ];
        const consumerColumnData = [
            {
                bio: infoText,
            },
        ];
        const myInit = {
            body: {
                columnData: props.isAdvisor ? advisorColumnData : consumerColumnData,
            },
        };

        try {
            let response = {};
            if (props.isAdvisor) {
                response = await advisorProfileService.update(props.user.professionalId, myInit, firmAdminAuth);
                await props.updateCompletenessScore();
            } else {
                response = await consumerProfileService.update(props.user.consumerId, myInit);
            }

            props.setUser({
                ...props.user,
                bio: response.bio,
                bioVideoLink: videoText,
                advisorWebsiteURL: websiteText,
                twitterURL: twitterText,
                linkedInURL: linkedInText,
            });
            setSubmitDisabled(false);
            props.onClose();
        } catch (error) {
            setSubmitDisabled(false);
            setErrors(error.toString());
            setShowErrorBanner(true);
            console.log(error);
        }
    }

    return (
        <>
            <div className={styles.intoFormContainer}>
                {props.isAdvisor && (
                    <div className={styles.bannerContainer}>
                        <Info size={20} color="var(--adfi_green500)" />{" "}
                        <span className={styles.bannerText}>
                            Provide some background details for your potential clients.
                        </span>
                    </div>
                )}
                <div className={styles.introForm}>
                    <label htmlFor="bioText">Bio Text</label>
                    <textarea
                        id="bioText"
                        className={styles.infoTextArea}
                        onChange={(e) => handleChange(e.target.value)}
                        value={infoText}
                    ></textarea>
                    <p className={styles.characterCount}>{characterCount} / 2500</p>
                </div>

                {props.isAdvisor && (
                    <>
                        <div className={styles.introForm}>
                            <label htmlFor="Twitter">Twitter URL (Optional)</label>
                            <input
                                className="input full"
                                placeholder="Twitter URL"
                                type="url"
                                id="Twitter"
                                value={twitterText}
                                onChange={(e) => setTwitterText(e.target.value)}
                            />
                            {errors.twitterText && <p className="error-message">{errors.twitterText}</p>}
                        </div>

                        <div className={styles.introForm}>
                            <label htmlFor="LinkedIn">LinkedIn URL (Optional)</label>
                            <input
                                className="input full"
                                placeholder="LinkedIn URL"
                                type="url"
                                id="LinkedIn"
                                value={linkedInText}
                                onChange={(e) => setLinkedInText(e.target.value)}
                            />
                            {errors.linkedInText && <p className="error-message">{errors.linkedInText}</p>}
                        </div>

                        <div className={styles.introForm}>
                            <label htmlFor="Website">Website URL (Optional)</label>
                            <input
                                className="input full"
                                type="url"
                                placeholder="Website URL"
                                id="Website"
                                value={websiteText}
                                onChange={(e) => setWebsiteText(e.target.value)}
                            />
                            {errors.websiteText && <p className="error-message">{errors.websiteText}</p>}
                        </div>

                        <div className={styles.introForm}>
                            <label htmlFor="bioVideo">Intro Video URL (Optional)</label>
                            <input
                                className="input full"
                                placeholder="Video URL"
                                type="url"
                                id="bioVideo"
                                value={videoText}
                                onChange={(e) => setVideoText(e.target.value)}
                            />
                        </div>
                    </>
                )}
            </div>
            {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>}

            <div className="modal-footer">
                <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>
                    Cancel
                </Button>
                <Button
                    className="modal-cancel-button"
                    color="gradient-button"
                    onClick={(e) => validateFields(e)}
                    disabled={submitDisabled}
                >
                    <Check size={20} />
                    Confirm
                </Button>
            </div>
        </>
    );
}
