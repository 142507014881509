import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import FormModal from "../../components/Modals/FormModal";
import ShareProfileForm from "../../components/Forms/ShareProfileForm/ShareProfileForm";
import CompletenessScoreInfo from "../../components/Forms/CompletenessScoreInfo/CompletenessScoreInfo";
import ProfessionalProfile from "../../components/ProfessionalProfile/ProfessionalProfile";
import RightPanel from "../../components/ProfessionalProfile/RightPanel/RightPanel";
import styles from "./AdvisorProfile.module.scss";
import advisorProfileService from "../../services/advisorProfile.service";
import consumerProfileService from "../../services/consumerProfile.service";
import { UserContext } from "../../Utils/UserContext";
import { useNavigate } from "react-router";
import { Context } from "../../Utils/GlobalStore/Store";
import ClipLoader from "react-spinners/ClipLoader";
import SEO from "../../components/SEO/seo";
import GlobalFooter from "../../components/GlobalFooter/GlobalFooter";
import useMixPanelEvent from "../../Utils/MixPanel";
import AdvisorProfileHeader from "./AdvisorProfileHeader";
import RSNIPDisclosureText from "../../components/Disclosures/RSNIPDisclosureText";
import AdvisorDisclosure from "../../components/Disclosures/AdvisorDisclosure";
import AdvisorFinderDisclosure from "../../components/Disclosures/AdvisorFinderDisclosure";
import ContactAdvisorForm from "../../components/Forms/ContactAdvisorForm/ContactAdvisorForm";
import { format } from "date-fns";

function AdvisorProfile() {
    const mixpanelTrack = useMixPanelEvent();

    const { user } = useContext(UserContext);
    const [state, dispatch] = useContext(Context);
    const navigate = useNavigate();

    let { professionalId } = useParams();
    const [editMode, setEditMode] = useState(false);
    const [advisor, setAdvisor] = useState({});
    const [isDesktop, setDesktop] = useState(window.innerWidth > 1040);
    const [loading, setLoading] = useState(true);
    const [myProfile, setMyProfile] = useState(false);
    const [showShareProfileModal, setShowShareProfileModal] = useState(false);
    const [showContactAdvisorModal, setShowContactAdvisorModal] = useState(
        window.location.href.includes("contact=true") ? true : false,
    );
    const [completenessScore, setCompletenessScore] = useState(advisor.profileCompletenessScore?.toString());
    const [completenessStyle, setCompletenessStyle] = useState({
        width: `${advisor.profileCompletenessScore ?? 0}%`,
    });
    const [showCompletenessScoreModal, setShowCompletenessScoreModal] = useState(false);
    const [firmAdminAuth, setFirmAdminAuth] = useState(false);
    const locaUser = JSON.parse(localStorage.getItem("user"));
    const isConsumer = locaUser ? locaUser.groups.includes("Consumer") : false;
    const isFirmAdmin = locaUser ? locaUser.groups.includes("FirmAdmin") : false;

    useEffect(async () => {
        setLoading(true);
        const [advisorResponse] = await Promise.all([getAdvisorInfo(), getSpecaltyOptions()]);

        const userAgent = navigator.userAgent.toLowerCase();
        const isPrerender = /prerender|Prerender/g.test(userAgent);
        mixpanelTrack("Profile View", "Advisor", `${advisorResponse?.firstName} ${advisorResponse?.lastName}`);
        setLoading(false);
        if (isFirmAdmin && advisorResponse.firmId === user?.firmID) {
            setFirmAdminAuth(true);
        } else if (isConsumer && !isPrerender) {
            const myInit = {
                body: {
                    consumerId: user.id,
                    firstName: user.firstName,
                    professionalId: advisorResponse.professionalId,
                },
            };

            try {
                await consumerProfileService.createLead(myInit);
            } catch (error) {
                console.error(error);
            }
        } else if (!user && !isPrerender) {
            //no one logged in... generate lead
            const myInit = {
                body: {
                    professionalId: advisorResponse.professionalId,
                },
            };

            try {
                await consumerProfileService.createLead(myInit);
            } catch (error) {
                console.error(error);
            }
        }

        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    }, [professionalId]);

    useEffect(() => {
        if ((advisor.cognitoUsername && locaUser?.userName === advisor?.cognitoUsername) || firmAdminAuth) {
            setMyProfile(true);
        } else {
            setMyProfile(false);
            setEditMode(false);
        }
    }, [advisor, firmAdminAuth]);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 1040);
    };

    async function getAdvisorInfo() {
        try {
            const advisorResponse = await advisorProfileService.getOne(professionalId);
            if ((!advisorResponse.accountEnabled || !advisorResponse.hasLoggedIn) && !user?.groups.includes("Admin")) {
                if (!isFirmAdmin || advisorResponse.firmId !== user?.firmID) {
                    navigate(`/404`, { replace: true });
                }
            }
            setAdvisor(advisorResponse);
            setCompletenessScore(advisorResponse.profileCompletenessScore?.toString());
            setCompletenessStyle({ width: `${advisorResponse.profileCompletenessScore ?? 0}%` });
            return advisorResponse;
        } catch (error) {
            console.error(error);
            // error state
            navigate(`/404`, { replace: true });
        }
    }

    async function getSpecaltyOptions() {
        if (state.specialties.length <= 0) {
            const specialtyResponse = await advisorProfileService.getSpecialties();
            dispatch({
                type: "SET_SPECIALTIES",
                payload: specialtyResponse.sort((a, b) => a.displayOrder - b.displayOrder),
            });
        }
        if (state.clientele.length <= 0) {
            const clienteleResponse = await advisorProfileService.getClientele();
            dispatch({
                type: "SET_CLIENTELE",
                payload: clienteleResponse.sort((a, b) => a.displayOrder - b.displayOrder),
            });
        }
        if (state.pricing.length <= 0) {
            const pricingResponse = await advisorProfileService.getPricing();
            dispatch({ type: "SET_PRICING", payload: pricingResponse });
        }
        if (state.services.length <= 0) {
            const servicesResponse = await advisorProfileService.getServices();
            dispatch({
                type: "SET_SERVICES",
                payload: servicesResponse.sort((a, b) => a.displayOrder - b.displayOrder),
            });
        }
        // if (state.assetRanges.length <= 0) {
        //     const assetRangeResponse = await advisorProfileService.getAssetRanges();
        //     dispatch({ type: 'SET_ASSETRANGES', payload: assetRangeResponse.assetRanges });
        // }
        if (state.credentials.length <= 0) {
            const credentials = await advisorProfileService.getCredentials();
            dispatch({ type: "SET_CREDENTIALS", payload: credentials });
        }
    }

    function showScheduleCallButton() {
        if (isConsumer || !user) {
            return true;
        }
        return false;
    }

    function handleShareProfile() {
        setShowShareProfileModal(true);
        mixpanelTrack(
            `Advisor: ${advisor.firstName} ${advisor.lastName} - ${advisor.email}`,
            "Event Type",
            "Share Button Clicked",
        );
    }

    const lastUpdated = () => {
        if (advisor.lastUpdatedDate) {
            const formattedDateString = format(new Date(advisor.lastUpdatedDate), "MM-dd-yyyy");
            return (
                <div className={styles.lastUpdatedDate}>
                    <b>Profile last updated</b>: {formattedDateString}
                </div>
            );
        }

        return;
    };

    return (
        <>
            {loading && (
                <div className="spinnerContainer">
                    <ClipLoader color={"var(--adfi_blue500)"} loading={loading} size={100} />
                </div>
            )}

            {Object.keys(advisor).length !== 0 && !loading && (
                <SEO
                    title={`${advisor.firstName} ${advisor.lastName} | Financial Advisor | ${advisor.city}, ${advisor.state}`}
                    description={`Meet ${advisor.firstName} ${advisor.lastName}, a financial advisor in ${advisor.city}, ${advisor.state}. View their services, specialties, education, and pricing on AdvisorFinder. Schedule a call with ${advisor.firstName} today.`}
                    name="AdvisorFinder"
                    type="profile"
                    canonical={`https://advisorfinder.com/app/advisor-profile/${advisor.professionalId}/${advisor.firstName}-${advisor.lastName}/`}
                />
            )}

            {Object.keys(advisor).length !== 0 && !loading && (
                <div
                    className={`${myProfile || showScheduleCallButton() ? styles.myPageContainer : styles.pageContainer} ${styles.sideNavMargin}`}
                >
                    {showShareProfileModal && (
                        <FormModal onClose={() => setShowShareProfileModal(false)} show={showShareProfileModal}>
                            <ShareProfileForm advisor={advisor}></ShareProfileForm>
                        </FormModal>
                    )}
                    {showCompletenessScoreModal && (
                        <FormModal
                            onClose={() => setShowCompletenessScoreModal(false)}
                            show={showCompletenessScoreModal}
                            title="Profile Completeness Score"
                        >
                            <CompletenessScoreInfo></CompletenessScoreInfo>
                        </FormModal>
                    )}
                    {showContactAdvisorModal && (
                        <FormModal
                            onClose={() => setShowContactAdvisorModal(false)}
                            show={showContactAdvisorModal}
                            noPadding={true}
                        >
                            <ContactAdvisorForm
                                advisor={advisor}
                                setShowContactAdvisorModal={setShowContactAdvisorModal}
                            />
                        </FormModal>
                    )}
                    <AdvisorProfileHeader
                        myProfile={myProfile}
                        advisor={advisor}
                        editMode={editMode}
                        isDesktop={isDesktop}
                        firmAdminAuth={firmAdminAuth}
                        handleShareProfile={handleShareProfile}
                        completenessScore={completenessScore}
                        setShowCompletenessScoreModal={setShowCompletenessScoreModal}
                        showScheduleCallButton={showScheduleCallButton}
                        completenessStyle={completenessStyle}
                        setEditMode={setEditMode}
                        setShowContactAdvisorModal={setShowContactAdvisorModal}
                    />
                    <div className={myProfile ? styles.myProfileContainer : styles.profileContainer}>
                        <ProfessionalProfile
                            advisor={advisor}
                            setAdvisor={setAdvisor}
                            editMode={editMode}
                            setCompletenessStyle={setCompletenessStyle}
                            setCompletenessScore={setCompletenessScore}
                            firmAdminAuth={firmAdminAuth}
                        ></ProfessionalProfile>
                        <RightPanel
                            advisor={advisor}
                            editMode={editMode}
                            setCompletenessStyle={setCompletenessStyle}
                            setCompletenessScore={setCompletenessScore}
                            showContactAdvisorModal={showContactAdvisorModal}
                            setShowContactAdvisorModal={setShowContactAdvisorModal}
                            firmAdminAuth={firmAdminAuth}
                        ></RightPanel>
                    </div>
                    <div className={styles.footerContainer}>
                        {!loading && (
                            <>
                                <AdvisorFinderDisclosure />
                                {advisor.disclosureText && <AdvisorDisclosure advisor={advisor} />}
                                {advisor.rsnipDisclosure && <RSNIPDisclosureText />}
                                {lastUpdated()}
                                <GlobalFooter background={true} />
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default AdvisorProfile;
