import { useEffect, useState } from "react";
import styles from "./ServiceDetailsForm.module.scss";
import { getUrl } from "aws-amplify/storage";
import { React, AlertCircle, Mail, ArrowLeft, ArrowRight } from "react-feather";
import Button from "../../Button/Button";
import isNotLoggedInAdvisor from "../../../Utils/isNotLoggedInAdvisor";

export default function ServiceDetailsForm(props) {
    const { setSelectedService } = props;
    const currentIndex = props?.serviceIndex;
    const arrayLength = props?.serviceArray?.length - 1;
    const service = props?.serviceArray[currentIndex];
    const isService = props.isService;

    const [imgURL, setImgURL] = useState("");

    useEffect(async () => {
        if (service.id) {
            const id = service.id;
            try {
                let url = "";
                if (isService) {
                    url = (await getUrl({ key: `services/${id}.svg` })).url.href;
                } else {
                    url = (await getUrl({ key: `credentials/${id}.png` })).url.href;
                }
                setImgURL(url);
            } catch (e) {
                console.error(e);
            }
        }
    }, [isService, service]);

    const handleBackScroll = () => {
        if (currentIndex === 0) {
            return setSelectedService(arrayLength);
        }

        if (currentIndex <= arrayLength) {
            return setSelectedService(currentIndex - 1);
        }
    };

    const handleForwardScroll = () => {
        if (currentIndex < arrayLength) {
            return setSelectedService(currentIndex + 1);
        }

        if (currentIndex === arrayLength) {
            return setSelectedService(0);
        }
    };

    const openContactModal = () => {
        props.setShowServiceDetails(false);
        props.setShowContactAdvisorModal(true);
    };

    return (
        <>
            <div className={styles.formContainer}>
                <div className={styles.serviceImg}>
                    <img loading="lazy" src={imgURL} alt="service_img"></img>
                </div>
                <div className={styles.serviceName}>{service.value}</div>
                {service.whatItInvolves && (
                    <div className={styles.greenInfoContainer}>
                        <AlertCircle size={20} />
                        {service.whatItInvolves}
                    </div>
                )}
                {service.whoItsFor && (
                    <div className={styles.infoSection}>
                        <h3>Who It's For:</h3>
                        <p>{service.whoItsFor}</p>
                    </div>
                )}
                {service.whatYouShouldKnow && (
                    <div className={styles.infoSection}>
                        <h4>What You Should Know: </h4>
                        <p>{service.whatYouShouldKnow}</p>
                    </div>
                )}
            </div>

            <div className={styles.modalFooter}>
                <span className={styles.footerScrollButtons}>
                    <Button color="clear-button white" onClick={() => handleBackScroll()}>
                        <ArrowLeft size={20} />
                    </Button>
                    <Button color="clear-button white" onClick={() => handleForwardScroll()}>
                        <ArrowRight size={20} />
                    </Button>
                </span>
                {isNotLoggedInAdvisor() && (
                    <Button color="gradient-button" onClick={() => openContactModal()}>
                        Contact
                        <Mail size={20} />
                    </Button>
                )}
            </div>
        </>
    );
}
