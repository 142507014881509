import React, { useState, useEffect, useContext } from "react";
import styles from "./RightPanel.module.scss";
import { Edit2, CheckCircle, Link, Info, UserPlus, Calendar } from "react-feather";
import QuickFacts from "../QuickFacts/QuickFacts";
import FormModal from "../../Modals/FormModal";
import PricingForm from "../../Forms/PricingForm/PricingForm";
import PricingInfo from "../../Forms/PricingInfo/PricingInfo";
import AdditionalDetailsForm from "../../../components/Forms/AdditionalDetailsForm/AdditionalDetailsForm";
import ServicesProvided from "../ServicesProvided/ServicesProvided";
import useGetProfilePicture from "../../../hooks/useGetProfilePicture";
import { UserContext } from "../../../Utils/UserContext";
import Button from "../../../components/Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import { getPricingDisplayName } from "../../Forms/PricingInfo/PricingInfo";

const classnames = require("classnames");

export default function RightPanel(props) {
    const { user } = useContext(UserContext);
    const [advisor, setAdvisor] = useState(props.advisor);
    const profilePictureURL = useGetProfilePicture(
        advisor.firmId && advisor.firmId > 0 ? advisor.professionalId : advisor.cognitoUsername,
    );
    const editMode = props.editMode;
    const [isDesktop, setDesktop] = useState(window.innerWidth > 1040);
    const [showPricingModal, setShowPricingModal] = useState(false);
    const [showAdditionalDetailsModal, setShowAdditionalDetailsModal] = useState(false);
    const [showPricingInfo, setShowPricingInfo] = useState(false);
    const [profilePicture, setProfilePicture] = useState("");
    const isConsumer = JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user")).groups.includes("Consumer")
        : false;
    const firmAdminAuth = props.firmAdminAuth;
    const [clientNumber, setClientNumber] = useState(advisor.clientNumber ? advisor.clientNumber : "");
    const [averageAccountSize, setAverageAccountSize] = useState(
        advisor.averageAccountSize ? advisor.averageAccountSize : "",
    );

    const updateMedia = () => {
        setDesktop(window.innerWidth > 1040);
    };

    useEffect(async () => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    }, []);

    useEffect(() => {
        setProfilePicture(profilePictureURL);
    }, [profilePictureURL]);

    function showScheduleCallButton() {
        if (!(advisor.calendlyUser || advisor.calendlyUrl) && !advisor.phoneNumber) {
            return false;
        } else if (isConsumer || !user) {
            return true;
        }
        return false;
    }

    function openDisclosureLink(type) {
        if (type === "finra") {
            window.open(advisor.finraUrl);
        } else if (type === "sec") {
            window.open(advisor.secUrl);
        }
    }

    async function updateCompletenessScore() {
        const completenessScore = await advisorProfileService.getCompletenessScore(advisor.professionalId);
        props.setCompletenessScore(completenessScore?.toString());
        props.setCompletenessStyle({ width: `${completenessScore}%` });
    }

    return (
        <>
            <div className={styles.rightPanelContainer}>
                {showPricingModal && (
                    <FormModal
                        onClose={() => setShowPricingModal(false)}
                        show={showPricingModal}
                        title="Pricing Models"
                    >
                        <PricingForm
                            onClose={() => setShowPricingModal(false)}
                            advisor={advisor}
                            setAdvisor={setAdvisor}
                            firmAdminAuth={firmAdminAuth}
                            updateCompletenessScore={updateCompletenessScore}
                        ></PricingForm>
                    </FormModal>
                )}
                {showPricingInfo && (
                    <FormModal
                        onClose={() => setShowPricingInfo(false)}
                        show={showPricingInfo}
                        title="Pricing Model Info"
                    >
                        <PricingInfo advisor={advisor}></PricingInfo>
                    </FormModal>
                )}
                {showAdditionalDetailsModal && (
                    <FormModal
                        onClose={() => setShowAdditionalDetailsModal(false)}
                        show={showAdditionalDetailsModal}
                        noPadding={true}
                        title="Add Clientele Information"
                    >
                        <AdditionalDetailsForm
                            advisor={advisor}
                            firmAdminAuth={firmAdminAuth}
                            setAdvisor={setAdvisor}
                            clientNumber={clientNumber}
                            setClientNumber={setClientNumber}
                            averageAccountSize={averageAccountSize}
                            setAverageAccountSize={setAverageAccountSize}
                        />
                    </FormModal>
                )}
                {isDesktop && (
                    <QuickFacts
                        advisor={advisor}
                        editMode={editMode}
                        setShowAdditionalDetailsModal={setShowAdditionalDetailsModal}
                        clientNumber={clientNumber}
                        averageAccountSize={averageAccountSize}
                    />
                )}
                <div />
                {!editMode && advisor.services && advisor.services.length > 0 && (
                    <ServicesProvided
                        advisor={advisor}
                        editMode={editMode}
                        isDesktop={isDesktop}
                        data={advisor.services}
                    ></ServicesProvided>
                )}

                {/* Pricing Models */}
                {(editMode || (advisor.pricingV2 && advisor.pricingV2.length > 0)) && (
                    <div className={styles.pricingModelsContainer}>
                        <div className={styles.pricingModelsHeader}>
                            <h2>Pricing Models</h2>
                            <Info
                                size={20}
                                className={styles.pricingModelModalButton}
                                onClick={() => setShowPricingInfo(true)}
                            />
                            {editMode && (
                                <Edit2
                                    onClick={() => setShowPricingModal(true)}
                                    size={16}
                                    style={{ cursor: "pointer" }}
                                />
                            )}
                        </div>
                        <div className={styles.modelsContainer}>
                            {advisor.pricingV2?.map((element, index) => (
                                <div
                                    className={styles.model}
                                    style={{ cursor: "pointer" }}
                                    key={index}
                                    onClick={() => setShowPricingInfo(true)}
                                >
                                    <CheckCircle size={16} color="var(--adfi_blue500)" />
                                    <span>{getPricingDisplayName(element).name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Disclosures & Documents */}

                <div className={styles.pricingModelsContainer}>
                    <div className={styles.pricingModelsHeader}>
                        <h2>Proof of License</h2>
                    </div>
                    <div className={styles.modelsContainer}>
                        {advisor.finraUrl && (
                            <div
                                className={styles.model}
                                style={{ cursor: "pointer" }}
                                onClick={() => openDisclosureLink("finra")}
                            >
                                <Link size={16} color="var(--adfi_blue500)" />
                                <span>BrokerCheck</span>
                            </div>
                        )}
                        {advisor.secUrl && (
                            <div
                                className={styles.model}
                                style={{ cursor: "pointer" }}
                                onClick={() => openDisclosureLink("sec")}
                            >
                                <Link size={16} color="var(--adfi_blue500)" />
                                <span>SEC Profile</span>
                            </div>
                        )}
                    </div>
                </div>

                {/* Mini profile / Call CTA*/}
                {showScheduleCallButton() && (
                    <div className={styles.miniProfileContainer}>
                        <div className={styles.miniProfileAdvisorInfo}>
                            {profilePicture && (
                                <>
                                    <div className={styles.profilePicContainer}>
                                        <img
                                            loading="lazy"
                                            className={classnames(
                                                styles.picturePreviewImg,
                                                editMode ? styles.cursorPointer : "",
                                                !(profilePicture === "/app/images/profilePictureDefault.svg")
                                                    ? styles.gradientBorder
                                                    : styles.pupleBorder,
                                            )}
                                            src={profilePicture}
                                            alt="profile_picture"
                                        ></img>
                                    </div>
                                </>
                            )}
                            <div className={styles.profileInfoContainer}>
                                <div className={styles.locationText}>
                                    {advisor.city}, {advisor.state}
                                </div>
                                <div className={styles.advisorNameText}>{advisor.displayName}</div>
                                <div className={styles.purpleText}>
                                    <UserPlus size={20} />
                                    <div>Financial Advisor</div>
                                </div>
                                {advisor.jobTitle && <div className={styles.jobTitle}>{advisor.jobTitle} at</div>}
                                <div className={styles.employerText}>{advisor.companyName}</div>
                            </div>
                        </div>
                        <div className={styles.miniProfileScheduleButton}>
                            <Button
                                color="gradient-button"
                                boxShadow={true}
                                onClick={() => props.setShowContactAdvisorModal(true)}
                            >
                                <Calendar size={20} />
                                Contact
                            </Button>
                        </div>
                    </div>
                )}

                {/* Hobbies and Interests */}

                {/* Community Involvement */}
            </div>
        </>
    );
}
