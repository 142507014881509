import React, { useEffect, useState } from "react";
import styles from "./Assessment.module.scss";
import Button from "../../components/Button/Button";
import { ArrowRight, ArrowLeft, Info } from "react-feather";
import useIsScreenWidth from "../../hooks/useIsScreenWidth";

function AssessmentLifeStage(props) {
    const { setCurrentStage, lifeStageFilters, setAssessmentSelection, preFill, mixpanelTrack } = props;
    const [currentLifeStage, setCurrentLifeStage] = useState(0);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (preFill?.q3) {
            setCurrentLifeStage(preFill?.q3.lifeStageIds[0]);
            setDisabled(false);
        }
        mixpanelTrack("Assessment Q3", "Page View", true);
    }, []);

    const selectLifeStage = (id) => {
        if (currentLifeStage === id) {
            setDisabled(true);
            return setCurrentLifeStage(0);
        }

        setDisabled(false);
        setCurrentLifeStage(id);
        setAssessmentSelection("q3", { lifeStageIds: [id] });
    };

    const nextStep = () => {
        const foundValue = lifeStageFilters.find((filter) => filter.id === currentLifeStage);
        const infoArray = {
            id: foundValue.id,
            value: foundValue.value,
        };

        mixpanelTrack("Assessment Q3", "User Response", infoArray);
        setCurrentStage(4);
    };

    return (
        <>
            <div className={styles.industryContainer}>
                <h1 className={styles.verticaloffsetmedium}>What life stage do you identify with?</h1>
                <div className={styles.industryTypesContainer}>
                    {lifeStageFilters.map((item) => {
                        return (
                            <div
                                className={
                                    currentLifeStage === item.id ? styles.industryTypeSelected : styles.industryType
                                }
                                key={item.id}
                                onClick={() => selectLifeStage(item.id)}
                            >
                                {item.value}
                            </div>
                        );
                    })}
                </div>
                {useIsScreenWidth(720) && (
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            flexWrap: "wrap",
                            marginTop: "30px",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <Button color="clear-button white" onClick={() => setCurrentStage(2)}>
                            <ArrowLeft size={20} />
                            Back
                        </Button>
                        <Button onClick={() => nextStep()} disabled={disabled}>
                            Next
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                )}
            </div>
            <div className={styles.infoMessageContainer}>
                <Info size={20} color="#ABAAB3" />{" "}
                <span>
                    This will help you narrow down your choices of financial advisors who likely have clients that are
                    in a similar stage of life as you, so they can provide relevant experience to you.
                </span>
            </div>
            {!useIsScreenWidth(720) && (
                <div className={styles.mobileBottomNav}>
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <Button color="clear-button white" onClick={() => setCurrentStage(2)}>
                            <ArrowLeft size={20} />
                            Back
                        </Button>
                        <Button onClick={() => nextStep()} disabled={disabled}>
                            Next
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

export default AssessmentLifeStage;
