import React, { useState, useEffect } from "react";
import styles from "./ContactAdvisorForm.module.scss";
import { ArrowRight } from "react-feather";
import Button from "../../Button/Button";
import consumerProfileService from "../../../services/consumerProfile.service";
import validateContactAdvisor from "../../../Utils/validateContactAdvisor";
import useMixPanelEvent from "../../../Utils/MixPanel";
import ClipLoader from "react-spinners/ClipLoader";
import TextInput from "../../TextInput/TextInput";
import advisorProfileService from "../../../services/advisorProfile.service";

//const classnames = require('classnames');

export default function ContactAdvisorForm(props) {
    const mixpanelTrack = useMixPanelEvent();

    const advisor = props.advisor;
    const currentUser = JSON.parse(localStorage.getItem("user"));

    const [hideText, setHideText] = useState(false);
    const [firmConfig, setFirmConfig] = useState(JSON.parse(localStorage.getItem("firmConfig")));
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [meetingDetails, setMeetingDetails] = useState(null);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [submited, setSubmited] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(async () => {
        if (currentUser) {
            if (currentUser.firstName && currentUser.lastName)
                setFullName(`${currentUser.firstName} ${currentUser.lastName}`);
            setEmail(currentUser.email);
        }
        let firmConfigFile = firmConfig;
        if (!firmConfig) {
            firmConfigFile = await advisorProfileService.getFirmConfig();
            setFirmConfig(firmConfigFile);
            console.log(firmConfigFile);
            localStorage.setItem("firmConfig", JSON.stringify(firmConfigFile));
        }
        const firmSettings = firmConfigFile.find((firm) => advisor.firmId === firm.firmId);
        if (firmSettings?.hideMeetingText) {
            setHideText(true);
        }
    }, []);

    useEffect(() => {
        if (!fullName || !email) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [fullName, email, meetingDetails]);

    const validateInput = (e) => {
        e.preventDefault();
        setErrors({});
        const validate = validateContactAdvisor({ fullName, email, meetingDetails });

        if (Object.keys(validate).length !== 0) {
            setErrors(validate);
            return;
        } else {
            onSubmit();
        }
    };

    async function onSubmit() {
        setSubmitDisabled(true);
        setLoading(true);
        const myInit = {
            body: {
                consumerId: currentUser?.id,
                professionalId: advisor.professionalId,
                advisorEmail: advisor.email,
                consumerName: fullName,
                consumerEmail: email,
                meetingFocus: meetingDetails,
                meetingFormat: "messageAdvisor",
            },
        };

        const fromMarketplace = window.location.href.includes("contact=true")
            ? "- Marketplace CTA"
            : "- Advisor Profile CTA";

        mixpanelTrack(`Advisor Contacted ${fromMarketplace}`, "Meeting Info", myInit.body);

        try {
            await consumerProfileService.createMeetingRequest(myInit);
            setSubmited(true);
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitDisabled(false);
            setLoading(false);
        }
    }

    return (
        <div>
            {loading && (
                <div className="spinnerContainer">
                    <ClipLoader color={"var(--adfi_blue500)"} loading={loading} size={100} />
                </div>
            )}
            {!submited && (
                <>
                    <div className={styles.formContainer}>
                        <div className={styles.headerContainer}>
                            <h1>
                                Contact {advisor.firstName} {advisor.lastName}
                            </h1>
                            {/* <ol>
                                <li>The Advisor will receive your message and contact information.</li>
                                <li>The advisor will reach out within a few days to schedule a follow-up meeting.</li>
                            </ol> */}
                        </div>
                        <TextInput
                            type="name"
                            id="name"
                            placeholder="John Doe"
                            topLabel={<b>Full Name</b>}
                            height="large"
                            value={fullName}
                            error={errors.fullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        <TextInput
                            type="email"
                            id="email"
                            topLabel={<b>Email</b>}
                            value={email}
                            height="large"
                            placeholder="john@email.com"
                            error={errors.email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {!hideText && (
                            <div className={styles.inputContainer}>
                                <div>
                                    <b>What are you looking for?</b> (optional)
                                </div>
                                <textarea
                                    className={styles.infoTextArea}
                                    placeholder="I am looking to save money for my child's college fund..."
                                    onChange={(e) => setMeetingDetails(e.target.value)}
                                ></textarea>
                                {errors.meetingDetails && <p className="error-message">{errors.meetingDetails}</p>}
                            </div>
                        )}
                    </div>
                    <div className={styles.formFooter}>
                        <span>
                            By submitting this form, I confirm I read the{" "}
                            <a href="https://www.advisorfinder.com/terms-of-service">Terms of Service</a> and agree to
                            them.
                        </span>
                        <Button
                            className="modal-cancel-button"
                            color="gradient-button"
                            onClick={(e) => validateInput(e)}
                            disabled={submitDisabled}
                            type="submit"
                        >
                            Submit
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                </>
            )}
            {submited && (
                <div className={styles.formContainerCompleted}>
                    <img
                        loading="lazy"
                        src="/app/images/contactedanimation.gif"
                        alt="Contact information sent"
                        className={styles.completeImg}
                    ></img>
                    {!hideText && (
                        <>
                            <h1 className={styles.submitText}>Thank you for contacting {advisor.firstName}</h1>
                            <span className={styles.submitSubtext}>
                                The advisor will receive your message and contact information, and will reach out within
                                a few days to schedule a follow-up meeting.
                            </span>
                            <Button onClick={() => props.setShowContactAdvisorModal(false)}>Back to profile</Button>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
