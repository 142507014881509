import validator from "validator";

function validateConfirmSignUp(values) {
    let errors = {};

    if (!validator.isEmail(values.email)) {
        errors.email = "Enter valid email";
    }

    if (!validator.matches(values.fullName ?? "", "^[a-zA-Z-,.' -]*$")) {
        errors.fullName = "Enter a valid name with no special characters";
    }

    return errors;
}

export default validateConfirmSignUp;
