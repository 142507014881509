import React, { useState } from "react";
import styles from "./AssessmentResults.module.scss";
import { MapPin, Star, Check } from "react-feather";
import Button from "../../components/Button/Button";
import useGetProfilePicture from "../../hooks/useGetProfilePicture";
import { useLocalStorage } from "../../Utils/useLocalStorage";
import useMixPanelEvent from "../../Utils/MixPanel";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import consumerProfileService from "../../services/consumerProfile.service";
import FormModal from "../../components/Modals/FormModal";

const classnames = require("classnames");

function AssessmentCard(props) {
    const mixpanelTrack = useMixPanelEvent();
    const [user, setUser] = useLocalStorage("user", null);
    const { advisor } = props;
    const { firmId, professionalId, cognitoUsername } = advisor;
    const [contactButtonText, setContactButtonText] = useState("Connect with Advisor");
    const [isInterestedStyling, setIsInterestedStyling] = useState();
    const [submitDisabled, setSubmitDisabled] = useState();
    const [detailsShared, setDetailsShared] = useState(false);
    const [showSendContactDetailsModal, setShowSendContactDetailsModal] = useState(false);
    const email = user?.email;
    const profilePicture = useGetProfilePicture(firmId && firmId > 0 ? professionalId : cognitoUsername);

    const [isFavorite, setIsFavorite] = useState(user?.favoriteAdvisors?.includes(professionalId));

    async function setFavoriteAdvisor(event, professionalId) {
        event.stopPropagation();
        const existingFavorite = user.favoriteAdvisors ?? [];
        const removeIndex = existingFavorite.findIndex((id) => id === professionalId);
        if (removeIndex !== -1) {
            existingFavorite.splice(removeIndex, 1);
            setIsFavorite(false);
        } else {
            existingFavorite.push(professionalId);
            setIsFavorite(true);
        }
        const myInit = {
            body: {
                columnData: [{ favoriteAdvisors: existingFavorite }],
            },
        };
        await consumerProfileService.update(user.id, myInit);
        user.favoriteAdvisors = existingFavorite;
        setUser(user);
    }

    const consumerInterested = (event) => {
        event.stopPropagation();
        mixpanelTrack("Connect with Advisor Click", {
            advisor_name: advisor.displayName,
            advisor_firm: advisor.firmId,
            advisor_location: `${advisor.city}, ${advisor.state} - ${advisor.zipCode}`,
            screen: "Assessment Results",
            interaction_type: "initial_click",
        });
        setShowSendContactDetailsModal(true);
    };

    const consumerSharedDetails = async (event) => {
        event.stopPropagation();
        setIsInterestedStyling(styles.isInterestedStageTwo);
        setShowSendContactDetailsModal(false);
        await onSubmit();
        setDetailsShared(true);
    };

    async function onSubmit() {
        setSubmitDisabled(true);
        const myInit = {
            body: {
                consumerId: user?.id,
                professionalId: advisor.professionalId,
                advisorEmail: advisor.email,
                consumerName: `${user.firstName} ${user.lastName}`,
                consumerEmail: email,
                meetingFormat: "messageAdvisor",
            },
        };

        mixpanelTrack("Connect with Advisor Conversion", {
            advisor_name: advisor.displayName,
            advisor_firm: advisor.firmId,
            advisor_location: `${advisor.city}, ${advisor.state} - ${advisor.zipCode}`,
            screen: "Assessment Results",
            interaction_type: "share_details_confirmed",
        });

        try {
            setContactButtonText("Sharing Details...");
            await consumerProfileService.createMeetingRequest(myInit);
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitDisabled(false);
            setContactButtonText("Details Shared");
        }
    }

    const sendAdvisorDetailsFormModalContent = () => {
        return (
            <div className={styles.formContainer}>
                <div className={styles.infoTextContainer}>
                    <h1>Share Contact Details</h1>
                    <span>
                        Your name and email will be sent to {advisor.firstName} {advisor.lastName}. You can expect them
                        to contact you in a few days!
                    </span>
                </div>
                <div className={styles.miniAdvisorProfile}>
                    <img
                        fetchPriority="high"
                        className={classnames(
                            styles.profilePictureMini,
                            !(profilePicture === "/app/images/profilePictureDefault.svg")
                                ? styles.gradientBorder
                                : styles.pupleBorder,
                        )}
                        src={profilePicture ?? "/app/images/profilePictureDefault.svg"}
                        alt="profile_picture"
                    />
                    <b>
                        {advisor.firstName} {advisor.lastName}
                    </b>
                </div>

                <Button disabled={submitDisabled} onClick={(e) => consumerSharedDetails(e)}>
                    <Check size={20} /> Confirm
                </Button>
            </div>
        );
    };

    return (
        <>
            {showSendContactDetailsModal && (
                <FormModal
                    onClose={() => setShowSendContactDetailsModal(false)}
                    show={showSendContactDetailsModal}
                    noPadding={true}
                >
                    {sendAdvisorDetailsFormModalContent()}
                </FormModal>
            )}
            <div
                className={styles.assessmentCardContainer}
                onClick={() =>
                    window.open(
                        `/app/advisor-profile/${advisor.professionalId}/${advisor.firstName}-${advisor.lastName}`,
                    )
                }
            >
                <div className={styles.assessmentCardImgContainer}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div className={styles.locationContainer}>
                            <MapPin size={15} /> {advisor.city}, {advisor.state}
                        </div>
                        <div
                            className={styles.favoriteContainer}
                            onClick={(e) => setFavoriteAdvisor(e, professionalId)}
                        >
                            {isFavorite ? <Star size={20} color="yellow" fill="yellow" /> : <Star size={20} />}
                        </div>
                    </div>
                    <img
                        fetchPriority="high"
                        className={classnames(
                            styles.profilePicture,
                            !(profilePicture === "/app/images/profilePictureDefault.svg")
                                ? styles.gradientBorder
                                : styles.pupleBorder,
                        )}
                        src={profilePicture ?? "/app/images/profilePictureDefault.svg"}
                        alt="profile_picture"
                    />
                </div>
                <div className={styles.assessmentCardImgContainerBottom} />
                <div className={styles.assessmentCardContentContainer}>
                    <span style={{ textAlign: "center" }}>
                        <h1>{advisor.displayName}</h1>
                        <h2>
                            Works at <b>{advisor.companyName}</b>
                        </h2>
                    </span>
                    <div>
                        <Button
                            color="clear-button fullWidth"
                            size="thin"
                            className={isInterestedStyling}
                            disabled={detailsShared}
                            onClick={(e) => consumerInterested(e)}
                        >
                            {detailsShared && <Check size={20} />}
                            <span className="shrinkText">{contactButtonText}</span>
                        </Button>
                    </div>
                    <span style={{ height: "90px" }}>
                        <p className={styles.tagsHeader}>Services Provided</p>
                        <div className={styles.tagsContainer}>
                            {advisor.services?.slice(0, 3).map((client, index) => (
                                <div key={index} className={styles.tag}>
                                    <b>{client.value}</b>
                                </div>
                            ))}
                            {advisor.services?.length > 3 && (
                                <div key={4} className={styles.tag}>
                                    <b>+{advisor.services?.length - 3}</b>
                                </div>
                            )}
                        </div>
                    </span>
                    <span style={{ height: "90px" }}>
                        <p className={styles.tagsHeader}>Typical Clientele</p>
                        <div className={styles.tagsContainer}>
                            {advisor.clientele?.slice(0, 3).map((client, index) => (
                                <div key={index} className={styles.tag}>
                                    <b>{client.value}</b>
                                </div>
                            ))}
                            {advisor.clientele?.length > 3 && (
                                <div key={4} className={styles.tag}>
                                    <b>+{advisor.clientele?.length - 3}</b>
                                </div>
                            )}
                        </div>
                    </span>
                    {advisor.averageAccountSize && (
                        <div className={styles.footerContainer}>
                            <span className={styles.footerStat}>
                                Average Account Size:
                                <span className={styles.spacingDots}>
                                    ....................................................................
                                </span>
                            </span>
                            <span>
                                {" "}
                                <b>{advisor.averageAccountSize}</b>
                            </span>
                        </div>
                    )}
                    {advisor.yearsOfExperience && (
                        <div className={styles.footerContainer}>
                            <span className={styles.footerStat}>
                                Years of Experience:
                                <span className={styles.spacingDots}>
                                    ....................................................................
                                </span>
                            </span>
                            <span style={{ whiteSpace: "nowrap" }}>
                                {" "}
                                <b>{advisor.yearsOfExperience}</b>
                            </span>
                        </div>
                    )}
                    {advisor.clientNumber && (
                        <div className={styles.footerContainer}>
                            <span className={styles.footerStat}>
                                Number of Clients:
                                <span className={styles.spacingDots}>
                                    ....................................................................
                                </span>
                            </span>
                            <span>
                                {" "}
                                <b>{advisor.clientNumber}</b>
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default AssessmentCard;
